<template>
  <!-- NAME[epic=库管] 报溢单 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="10px"
    >
      <el-form-item label="" prop="">
        <el-select
          v-model="form.depot_id"
          clearable
          style="width: 140px"
          placeholder="请选择仓库"
        >
          <el-option
            v-for="(i, idx) in depotList"
            :key="idx"
            :value="i.id"
            :label="i.depot_name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.loss_status"
          clearable
          style="width: 140px"
          placeholder="状态"
        >
          <el-option
            v-for="(i, idx) in statusSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.time_type"
          clearable
          style="width: 140px"
          placeholder="请选择申请时间"
        >
          <el-option
            v-for="(i, idx) in timeSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-input
          v-model="form.keyword"
          placeholder="输入单号、商品名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleCheck(row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right" align="center">
        <template #default="{ row }">
          <el-button type="text" @click="handleCheck(row)">查看</el-button>
          <el-button
            v-show="row.loss_status == 1"
            type="text"
            @click="handleReview(row)"
          >
            审核
          </el-button>
          <el-button type="text" @click="handlePrint(row)">打印</el-button>
          <!-- v-show="row.loss_status_text == '待审核'" -->
          <el-popconfirm
            icon="el-icon-info"
            icon-color="red"
            title="确定作废吗？"
            @confirm="deleterouter(row)"
          >
            <el-button slot="reference" style="margin-left: 10px" type="text">
              作废
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="form.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></el-pagination>
    <create ref="create" @refresh="handleQuery"></create>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { postAction } from '@/api/Employee'
  import Create from './component/create'
  export default {
    name: 'BaoYiDan',
    components: { Create },
    data() {
      return {
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          list: '/depotAdmin/bill-loss/bill-list',
          delete: 'depotAdmin/bill-loss/scrap',
        },
        loading: false,
        depotList: [],
        status: [],
        time: [],
        timeSelect: [
          {
            id: 1,
            name: '申请时间',
          },
          {
            id: 2,
            name: '审核时间',
          },
        ],
        form: {
          loss_type: 2, //报溢单2
          depot_id: '',
          keyword: '',
          pageNo: 1,
          pageSize: 10,

          start_time: '',
          end_time: '',
          time_type: 1,
          loss_status: null,
        },
        total: 0,
        list: [],
        statusSelect: [
          {
            id: 1,
            name: '待审核',
          },
          {
            id: 2,
            name: '已审核',
          },
          {
            id: 3,
            name: '已作废',
          },
        ],
        checkList: [
          '单号',
          '仓库',
          '金额',
          '状态',
          '申报人',
          '申请时间',
          '审核人',
          '审核时间',
          '备注',
        ],
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'loss_code',
            width: '200',
          },
          {
            order: 2,
            label: '仓库',
            prop: 'depot_name',
            width: '120',
          },
          {
            order: 3,
            label: '金额',
            prop: 'total_amount',
            width: '100',
          },
          {
            order: 4,
            label: '状态',
            prop: 'loss_status_text',
            width: '100',
          },
          {
            order: 5,
            label: '申报人',
            prop: 'add_name',
            width: '100',
          },
          {
            order: 6,
            label: '申请时间',
            prop: 'create_at',
            width: '130',
          },
          {
            order: 7,
            label: '审核人',
            prop: 'check_name',
            width: '100',
          },
          {
            order: 8,
            label: '审核时间',
            prop: 'check_time',
            width: '130',
          },
          {
            order: 9,
            label: '备注',
            prop: 'remark',
            width: '130',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.depotSelect()
      this.fetchData()
    },
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(this.url.list, this.form)
        this.total = totalCount
        this.list = data
        this.loading = false
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {},
      // 仓库下拉
      depotSelect() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            this.depotList = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.handleQuery()
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleAdd() {
        this.$refs.create.showDialog = true
        // type1添加 2查看 3审核
        this.$refs.create.type = 1
        this.$refs.create.title = '添加报溢单'
      },
      handleCheck(row) {
        this.$refs.create.showDialog = true
        this.$refs.create.id = row.id
        // type1添加 2查看 3审核
        this.$refs.create.type = 2
        this.$refs.create.title = '报溢单详情'
      },
      handleReview(row) {
        this.$refs.create.showDialog = true
        this.$refs.create.id = row.id
        // type1添加 2查看 3审核
        this.$refs.create.type = 3
        this.$refs.create.title = '报溢单审核'
      },
      handlePrint(row) {},
      // 作废
      deleterouter(row) {
        postAction(this.url.delete, { bill_id: row.id }).then((res) => {
          console.log(res, '')
          this.$message.success(res.msg)
          this.fetchData()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
